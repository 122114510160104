label {
	display: flex;
	flex-direction: column;
	color: #5e5e5e;
	font: 600 14px/26px 'Open Sans', Arial, sans-serif;
}

input {
	border-radius: 2.5px;
	border: 1px solid #979797;
	min-height: 46px;
	padding: 10px;
}

textarea {
	min-height: 200px;
	border-radius: 2.5px;
	border: 1px solid #979797;
	padding: 10px;
	resize: none;
}

.span__grid {
	grid-column: 1 / -1;
	.btn {
		float: right;
		margin-top: 1rem;
	}
}

.form__grid {
	display: grid;
	grid-gap: 1rem;
}

@media screen and (min-width: 800px) {
	.form__grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

.ginput_container {
	input {
		border-radius: 2.5px;
		border: 1px solid #979797;
		min-height: 46px;
		padding: 10px;
	}
	
	textarea {
		min-height: 200px;
		border-radius: 2.5px;
		border: 1px solid #979797;
		padding: 10px;
		resize: none;
	}
}

.gfield_label {
	display: flex;
	flex-direction: column;
	color: #5e5e5e;
	font-size: 14px !important;
	font-family: 'Open Sans', Arial, sans-serif;
	font-weight: 600 !important;
}
.gform_button {
	background-color: #24a0c6;
    color: #fff;
	float: right;
    margin-top: 1rem;
	font-weight: 400;
    font-size: 16px;
    min-width: 170px;
    min-height: 46px;
    padding: 4px 10px;
    border-radius: 4px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
	cursor: pointer;
	&:hover, &:focus {
		filter: brightness(1.2);
	}
}

.gfield_required {
	color: inherit !important;
}