.banner {
	width: 100%;
	background-color: #bae9e4;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 20px 30px;
		.content {
			width: fit-content;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			p {
				margin: 0 1rem 0 0;
				color: #000;
			}
			a {
				color: #000;
				margin-right: 10px;
				&:focus,
				&:hover {
					text-decoration: underline;
				}
			}
			svg {
				display: inline-flex;
			}
		}
	}
}

#app-nav {
	.banner {
		.wrapper {
			padding: 10px 30px;
			a {
				font-weight: 600;
				text-decoration: underline;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.banner {
		.wrapper {
			padding: 20px 60px;
		}
	}
	#app-nav {
		.banner {
			.wrapper {
				padding: 11px 60px;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.banner {
		.wrapper {
			padding: 20px 90px;
		}
	}
	#app-nav {
		.banner {
			.wrapper {
				padding: 11px 60px;
			}
		}
	}
}
