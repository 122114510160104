.hamburger {
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0 0 0 1rem;
	overflow: visible;
	display: flex;
}
@media screen and (min-width: 400px) {
	.hamburger {
		margin: 0 0 0 2rem;
	}
}

.hamburger:focus {
	box-shadow: none;
}
.hamburger:hover {
	opacity: 0.7;
}
.hamburger.is-active:hover {
	opacity: 0.7;
}

.hamburger.is-active {
	position: fixed;
	z-index: 10;
	top: 40px;
	right: 30px;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
	background-color: #000;
}

.hamburger-box {
	width: 34px;
	height: 24px;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -2px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 34px;
	height: 3px;
	background-color: #fff;
	border-radius: 4px;
	position: absolute;
	transition-property: transform;
	transition-duration: 0.15s;
	transition-timing-function: ease;
	transition: background-color 0.125s ease-in-out 0.175s;
}
.filled,
.permaFilled {
	.hamburger-inner,
	.hamburger-inner::before,
	.hamburger-inner::after {
		background-color: #000;
	}
}
.hamburger-inner::before,
.hamburger-inner::after {
	content: '';
	display: block;
}
.hamburger-inner::before {
	top: -10px;
}
.hamburger-inner::after {
	bottom: -10px;
}

/*
   * Emphatic
   */
.hamburger--emphatic {
	overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
	transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
	left: 0;
	transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
		top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
	transition: background-color 0.125s ease-in 0.175s;
}
.hamburger--emphatic .hamburger-inner::after {
	top: 10px;
	right: 0;
	transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
		top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
	transition: background-color 0.125s ease-in 0.175s;
}
.hamburger--emphatic.is-active .hamburger-inner {
	transition-delay: 0s;
	transition-timing-function: ease-out;
	background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
	left: -80px;
	top: -80px;
	transform: translate3d(80px, 80px, 0) rotate(45deg);
	transition: left 0.125s ease-out, top 0.05s 0.125s linear,
		transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
	right: -80px;
	top: -80px;
	transform: translate3d(-80px, 80px, 0) rotate(-45deg);
	transition: right 0.125s ease-out, top 0.05s 0.125s linear,
		transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
