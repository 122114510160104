footer {
	background-color: #000;
	.wrapper {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 30px;
	}
}

footer.limited {
	.logo__container {
		flex: 0.2;
		margin: 0 0 2rem;
		img {
			max-width: 162px;
			max-height: 90px;
		}
	}
	.links__container {
		flex: 0.75;
		.social {
			display: flex;
			margin-bottom: 1rem;
			a {
				margin-right: 1rem;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.links {
			display: flex;
			flex-direction: column-reverse;
			p {
				color: #c9c9c9;
				font-size: 14px;
				line-height: 19px;
				margin: 0 10px 0 0;
			}
			.links__row {
				display: flex;
				align-items: center;
				margin-bottom: 1rem;
				a {
					position: relative;
					height: 19px;
					color: #c9c9c9;
					font-size: 14px;
					line-height: 19px;
					padding-right: 1rem;
					margin-right: 10px;
					&::after {
						display: block;
						height: 14px;
						width: 1px;
						content: '';
						position: absolute;
						top: 3px;
						right: 0;
						background-color: #c9c9c9;
					}
					&:last-child {
						margin-right: 0;
						padding-right: 0;
						&::after {
							display: none;
						}
					}
					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

footer.full {
	.wrapper {
		.row {
			display: flex;
			flex-direction: column;
			.link__block {
				display: flex;
			}
			.image__block {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
		.logo__container {
			flex: 0.2;
			margin: 0 0 2rem;
			img {
				max-width: 162px;
				max-height: 90px;
			}
		}
		.social {
			display: flex;
			margin-bottom: 1rem;
			a {
				margin-right: 1rem;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		.links__container {
			margin-bottom: 1rem;
			min-width: 100px;
			margin-right: 1rem;
			li {
				margin-bottom: 0.5rem;
				a {
					color: #c9c9c9;
					font-size: 14px;
					line-height: 19px;
					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}
		}
		.legal__links {
			.links {
				p {
					color: #c9c9c9;
					font-size: 14px;
					line-height: 19px;
					margin: 0 0 1rem 0;
				}
				.links__row {
					display: flex;
					flex-wrap: wrap;
					a {
						position: relative;
						height: 19px;
						padding-right: 1rem;
						margin-right: 10px;
						&::after {
							display: block;
							height: 14px;
							width: 1px;
							content: '';
							position: absolute;
							top: 3px;
							right: 0;
							background-color: #c9c9c9;
						}
						&:last-child {
							margin-right: 0;
							padding-right: 0;
							&::after {
								display: none;
							}
						}
					}
				}
			}
			.tag p,
			.tag p a,
			.links .links__row a {
				color: #c9c9c9;
				font-size: 14px;
				line-height: 19px;
				margin-bottom: 1rem;
			}
			.tag p a {
				text-decoration: underline;
				color: #fff;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

@media screen and (min-width: 800px) {
	footer {
		.wrapper {
			padding: 50px 60px;
		}
	}
	footer.limited {
		.wrapper {
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.logo__container {
				margin: 0;
			}
			.links__container {
				.social {
					justify-content: flex-end;
				}
				.links {
					margin-top: auto;
					align-items: flex-end;
					justify-content: flex-end;
					text-align: right;
				}
			}
		}
	}
	footer.full {
		.wrapper {
			flex-direction: column;
			.row {
				flex-direction: row;
				justify-content: space-between;
			}
			.legal__links {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-top: 3rem;
				.links {
					p {
						margin-right: 1rem;
					}
					display: flex;
				}
			}
			.links__container {
				min-width: 180px;
				li {
					margin-bottom: 0.75rem;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	footer {
		.wrapper {
			padding: 50px 90px;
		}
	}
	footer.limited {
		.links__container {
			.links {
				flex-direction: row;
				align-items: center;
				.links__row {
					margin-bottom: 0;
				}
			}
		}
	}
	footer.full {
		.wrapper {
			padding: 90px 90px 50px;
		}
	}
}
