.newsTemplate {
	padding-top: 200px;
	.wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 1000px;
		margin: 0 auto;
		padding: 60px 30px;
		img {
			margin-bottom: 2rem;
		}
		.date {
			color: #24a0c6;
			font: 400 16px/26px 'Open Sans', Arial, sans-serif;
			letter-spacing: -0.36px;
			margin-bottom: 1rem;
		}
		h1 {
			color: #292c2f;
			font: 700 36px/46px helvetica, Arial, sans-serif;
			letter-spacing: -0.47px;
			margin-bottom: 2rem;
		}
		.content {
			margin-bottom: 3rem;
			a {
				color: #5e5e5e;
				font-weight: 700;
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
			video {
				margin: 1rem 0;
				width: 100%;
			}
			img {
				margin: 1rem 0;
			}
		}
		.btn {
			width: fit-content;
		}
	}
}

@media screen and (min-width: 800px) {
	.newsTemplate {
		padding-top: 150px;
		.wrapper {
			padding: 90px 60px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.newsTemplate {
		.wrapper {
			padding: 90px;
		}
	}
}
