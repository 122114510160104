.textBlock {
	width: 100%;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		text-align: center;
		.content {
			width: 100%;
			max-width: 880px;
			margin: 0 auto;
			h3 {
				font: 700 36px/46px 'Open Sans', Arial, sans-serif;
				letter-spacing: -0.47px;
			}
			p {
				color: #5e5e5e;
			}
			.btn {
				min-height: 50px;
				margin-top: 1rem;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.textBlock {
		.wrapper {
			padding: 90px 60px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.textBlock {
		.wrapper {
			padding: 90px;
		}
	}
}
