.formWithContent {
	width: 100%;
	padding-top: 150px;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		.image__block {
			flex: 1;
			margin-bottom: 2rem;
			img {
				height: 100%;
				border-radius: 5px;
				box-shadow: (2px 2px 10px 0 #bbbbbb);
			}
		}
		.content__block {
			flex: 1;
			h1 {
				font: 400 20px/24px 'Open Sans', Arial, sans-serif;
				letter-spacing: -0.25px;
				margin: 0 0 1rem 0;
				color: #24a0c6;
			}
			.form-title {
				font: 700 50px/56px helvetica, Arial, sans-serif;
				letter-spacing: -1px;
				color: #292c2f;
			}
			p {
				color: #5e5e5e;
			}
			.btn {
				min-height: 50px;
				margin-top: 1rem;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.formWithContent {
		min-height: 70vh;
		.wrapper {
			padding: 90px 60px;
			flex-direction: row;
			align-items: flex-start;
			.image__block {
				margin-bottom: 0;
				padding-left: 1rem;
			}
			.content__block {
				padding-right: 1rem;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.formWithContent {
		.wrapper {
			padding: 90px;
			.image__block {
				padding-left: 2rem;
			}
			.content__block {
				padding-right: 3rem;
			}
		}
	}
}
