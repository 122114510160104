.partnerBlock {
	width: 100%;
	background-color: #f1f4f4;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		.content {
			max-width: 880px;
			margin: 0 auto 3rem;
			text-align: center;
		}
		.logo__grid {
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: 1fr;
			.grid__block {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.partnerBlock {
		.wrapper {
			padding: 90px 60px;
			.logo__grid {
				grid-gap: 2rem;
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.partnerBlock {
		.wrapper {
			padding: 90px;
			.logo__grid {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}
}
