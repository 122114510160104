.fullImage {
	width: 100%;
	background-color: #002330;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 50px 30px;
	}
}

@media screen and (min-width: 800px) {
	.fullImage {
		.wrapper {
			padding: 50px 60px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.threeColumnContent {
		.wrapper {
			padding: 50px 90px;
		}
	}
}
