@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Light.ttf');
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Light.ttf') format('woff2'),
		url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Light.ttf') format('woff');
	font-weight: 300;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Regular.ttf');
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Regular.ttf') format('woff2'),
		url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Regular.ttf') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Medium.ttf');
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Medium.ttf') format('woff2'),
		url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Medium.ttf') format('woff');
	font-weight: 500;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-SemiBold.ttf');
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('woff2'),
		url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('woff');
	font-weight: 600;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Bold.ttf');
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Bold.ttf') format('woff2'),
		url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-Bold.ttf') format('woff');
	font-weight: 700;
}

@font-face {
	font-family: 'Open Sans';
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-ExtraBold.ttf');
	src: url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-ExtraBold.ttf') format('woff2'),
		url('/wp-content/themes/ie-hub/assets/fonts/open-sans/OpenSans-ExtraBold.ttf') format('woff');
	font-weight: 800;
}

h1 {
	font: 400 18px/22px 'Open Sans', Arial, sans-serif;
	letter-spacing: -0.2px;
	margin: 0 0 1rem 0;
}

h2 {
	font: 400 20px/24px 'Open Sans', Arial, sans-serif;
	letter-spacing: -0.25px;
	margin: 0 0 1rem 0;
	color: #24a0c6;
}

h3 {
	font: 700 18px/26px 'Open Sans', Arial, sans-serif;
	letter-spacing: -0.4px;
	margin: 0 0 1rem 0;
}

h4 {
	font: 700 1.5rem/2rem 'Open Sans', Arial, sans-serif;
	margin: 0 0 1rem 0;
}

h5 {
	font: 400 1.5rem/2rem 'Open Sans', Arial, sans-serif;
	margin: 0 0 1rem 0;
}

p {
	font: 400 1rem/1.75rem 'Open Sans', Arial, sans-serif;
	margin: 0 0 1rem 0;
}

@media all and (min-width: 800px) {
	h1 {
		font: 400 20px/24px 'Open Sans', Arial, sans-serif;
		letter-spacing: -0.25px;
	}
}

@media all and (min-width: 1024px) {
}
