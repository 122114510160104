.servicesBlock {
	width: 100%;
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		.service__container {
			width: 100%;
			border: 1px solid #d8d8d8;
			border-radius: 5px;
			padding: 25px;
			margin-bottom: 30px;
			&:last-child {
				margin-bottom: 0;
			}
			h2 {
				font: 700 36px/46px helvetica, Arial, sans-serif;
				letter-spacing: -0.47px;
				color: #292c2f;
				margin-bottom: 2rem;
			}
			ul {
				max-width: 860px;
			}
			ol,
			p,
			ul {
				margin-bottom: 2rem;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.servicesBlock {
		.wrapper {
			padding: 90px 60px;
			.service__container {
				padding: 50px;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.servicesBlock {
		.wrapper {
			padding: 90px;
		}
	}
}
