.videoWithContent {
	width: 100%;
	background-color: #f1f4f4;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		.video__block {
			flex: 1;
			margin-bottom: 2rem;
			position: relative;
			video {
				height: 100%;
				border-radius: 5px;
				box-shadow: (2px 2px 10px 0 #bbbbbb);
			}
			.play__button {
				position: absolute;
				top: calc(50% - 45px);
				left: calc(50% - 45px);
				z-index: 3;
				cursor: pointer;
			}
		}
		.content__block {
			flex: 1;
			h3 {
				font: 700 36px/46px helvetica, Arial, sans-serif;
				letter-spacing: -0.47px;
				color: #292c2f;
			}
			p {
				color: #5e5e5e;
			}
			.btn {
				min-height: 50px;
				margin-top: 1rem;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.videoWithContent {
		.wrapper {
			padding: 90px 60px;
			flex-direction: row-reverse;
			align-items: flex-start;
			.video__block {
				margin-bottom: 0;
			}
			.content__block {
				padding-right: 1rem;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.videoWithContent {
		.wrapper {
			padding: 90px;
			.video__block {
				max-width: 620px;
				margin-left: auto;
			}
			.content__block {
				padding-right: 3rem;
				max-width: 540px;
			}
		}
	}
}
