@-ms-viewport {
	width: device-width;
}

@-o-viewport {
	width: device-width;
}

@viewport {
	width: device-width;
}

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	background: #fff;
	font: 400 16px/24px 'Open Sans', Arial, sans-serif;
	color: #5e5e5e;
	text-rendering: optimizeLegibility;
	text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
}

* {
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

a {
	text-decoration: none;
}

ul {
	margin: 0;
	padding: 0;
}

ol {
	margin: 0 0 1rem 1.25rem;
	padding: 0;
	li {
		list-style-type: decimal;
	}
}

li {
	list-style-type: none;
}

img,
svg {
	display: block;
	max-width: 100%;
}

figure {
	margin: 0;
}

fieldset {
	display: block;
	padding: 0;
	margin: 0;
	border: 0;
}

ul.tick__list {
	margin-bottom: 1rem;
	li {
		margin: 0 0 2rem 25px;
		padding-left: 16px;
		position: relative;
		&::before {
			position: absolute;
			display: block;
			height: 25px;
			width: 28px;
			content: '';
			background-image: url('/assets/icons/tick-icon.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			z-index: 1;
			top: 0;
			left: -28px;
		}
		&.blank::before {
			display: none;
		}
	}
}
