.contentBlock {
    width: 100%;
    padding-top: 200px;
    
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 60px 30px; 
        
        h1 {
            font: 700 50px/56px helvetica,Arial,sans-serif;
            letter-spacing: -1px;
            color: #292c2f;
            margin-bottom: 2rem;
        }
        
        h3 {
            font: 700 18px/26px helvetica,Arial,sans-serif;
            letter-spacing: -.4px;
            margin-bottom: 1rem;
            color: #292c2f;
        }
    }
}

@media screen and (min-width: 800px) {
	.contentBlock {
		padding-top: 150px;
		.wrapper {
			padding: 90px 60px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.contentBlock {
		.wrapper {
			padding: 90px;
		}
	}
}
