.microsite-header {
    width: 100%;
    .wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        justify-content: space-between;
        padding: 40px 90px;
        @media (max-width: 1024px) {
            padding: 50px 60px;
        }
        @media (max-width: 799px) {
            padding: 30px;
            display: block;
        }
        
        .company-logo {
            height: 63px;
            max-width: 100%;

            @media (max-width: 991px) {
                margin-top: 30px;    
            }
        }
    }
}

.microsite {
    min-height: calc(100vh - 161px);
    background-color: #f2f4f4;
}

.microsite-block {
    .wrapper {
        @media (min-width: 1024px) {
            padding: 50px 90px !important;
        }
        @media (max-width: 991px) {
            flex-direction: column;
        }
        align-items: center;
        h1 {
            margin-bottom: 30px;
        }
        .content__block {
            p {
                color: #5E5E5E;
                font-family: "Open Sans";
                font-size: 20px;
                letter-spacing: 0;
                line-height: 28px;
                margin-bottom: 27px;
            }
            @media (max-width: 991px) {
                margin-bottom: 40px;
            }
        }
    }
}

.get-started {
    display: inline-flex !important; 

    @media(max-width: 991px) {
        display: none !important;
    }
}

.get-started-mobile {
    display: none !important; 
margin-right: auto;
    @media(max-width: 991px) {
        display: inline-flex !important;
    }
}