.newsLatest,
.newsList {
	width: 100%;
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		h2 {
			margin-bottom: 3rem;
			font-family: helvetica;
			color: #292c2f;
		}
		.news__grid {
			display: grid;
			grid-gap: 2rem;
			grid-template-columns: 1fr;
			.grid__block {
				display: flex;
				flex-direction: column;
				img {
					object-fit: cover;
					margin-bottom: 1rem;
				}
				.date {
					color: #24a0c6;
					font: 400 16px/26px 'Open Sans', Arial, sans-serif;
					letter-spacing: -0.36px;
					margin-bottom: 1rem;
					margin-top: 0;
				}
				h3 {
					font: 700 18px/26px helvetica, Arial, sans-serif;
					letter-spacing: -0.4px;
					margin-bottom: 1rem;
					color: #292c2f;
				}
				p {
					color: #5e5e5e;
					margin-top: auto;
				}
				.btn {
					width: fit-content;
					min-width: 120px;
				}
			}
		}
	}
}

.newsList {
	padding-top: 200px;
	.wrapper {
		h1 {
			font: 400 20px/24px 'Open Sans', Arial, sans-serif;
			letter-spacing: -0.25px;
			color: #24a0c6;
		}
		h2 {
			font: 700 50px/56px helvetica, Arial, sans-serif;
			letter-spacing: -1px;
			color: #292c2f;
			margin-bottom: 6rem;
		}
	}
}

@media screen and (min-width: 800px) {
	.newsLatest,
	.newsList {
		.wrapper {
			padding: 90px 60px;
			.news__grid {
				grid-template-columns: 1fr 1fr;
				.grid__block {
					img {
						height: 270px;
					}
				}
			}
		}
	}
	.newsList {
		padding-top: 150px;
	}
}

@media screen and (min-width: 1024px) {
	.newsLatest,
	.newsList {
		.wrapper {
			padding: 90px;
			.news__grid {
				grid-gap: 4rem;
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}
