.contactBlock {
	width: 100%;
	padding-top: 200px;
	.wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		h1 {
			font: 400 20px/24px 'Open Sans', Arial, sans-serif;
			letter-spacing: -0.25px;
			color: #24a0c6;
		}
		h2 {
			font: 700 50px/56px helvetica, Arial, sans-serif;
			letter-spacing: -1px;
			color: #292c2f;
			margin-bottom: 6rem;
		}
		
		a {
			color: #5e5e5e;
			text-decoration: none;
			&:hover {
				color: #5e5e5e;
			}
		}
		.contact__grid {
			display: grid;
			grid-gap: 2rem;
			grid-template-columns: 1fr;
			width: 100%;
			.grid__block {
				display: flex;
				flex-direction: column;
				img {
					object-fit: cover;
					margin-top: 3rem;
				}
				h3 {
					font: 700 18px/26px helvetica, Arial, sans-serif;
					letter-spacing: -0.4px;
					margin-bottom: 1rem;
					color: #292c2f;
				}
				p,
				address {
					color: #5e5e5e;
					font-style: normal;
				}
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.contactBlock {
		padding-top: 150px;
		.wrapper {
			padding: 90px 60px;
			.contact__grid {
				grid-template-columns: 1fr 1fr;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.contactBlock {
		.wrapper {
			padding: 90px;
		}
	}
}
