a.btn,
button.btn {
	font-weight: 400;
	font-size: 16px;
	min-width: 170px;
	min-height: 46px;
	padding: 4px 10px;
	border-radius: 4px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: none;
	&.wide {
		min-width: 200px;
	}
}
a.btn:hover,
a.btn:focus,
button.btn:hover,
button.btn:focus {
	filter: brightness(1.2);
	color: #fff !important;
}

a.btn.primary,
button.btn.primary {
	background-color: #24a0c6;
	color: #fff;
}

a.btn.secondary,
button.btn.secondary {
	background-color: #33a396;
	color: #fff;
}

.btn__unstyled {
	min-width: unset;
	min-height: unset;
	padding: 0;
	margin: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
	box-shadow: none;
}

// a.btn.accent,
// button.btn.accent {
// 	background-color: var(--accent-clr);
// 	color: #fff;
// }
