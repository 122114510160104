.whitepapersFormBlock {
	width: 100%;
	background-color: #ffffff;
	.wrapper {
		display: flex;
		align-items: stretch;
		text-align: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		padding: 60px 30px;
		&.formWrapper {
			// max-width: 602px;
			max-width: 662px;
		}
		margin: 0 auto;
		.content {
			max-width: 880px;
			margin: 0 auto 3rem;
			text-align: center;
		}
		form {
			text-align: left;
		}
	}

	.downloadsBlock {
		text-align: left;
		width: 100%;

		.downloadRow {
			border-bottom: 1px solid #D8D8D8;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding-top: 28px;
			padding-bottom: 28px;

			&.downloadHeader {
				padding-top: 0;
				padding-bottom: 8px;
				font-size: 14px;
				line-height: 26px;
				font-weight: 600;
				color: #5E5E5E;
			}
			
			h3 {
				flex-grow: 1;
				margin-bottom: 0;
				font-family: helvetica;
				line-height: 26px;
				font-size: 18px;
				font-weight: bold;
				letter-spacing: -0.4px;
				color: #292C2F;
			}

			.btn {
				min-width: 130px;
				margin-left: 28px;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.whitepapersFormBlock {
		.wrapper {
			padding: 90px 60px;
			&.formWrapper {
				max-width: 722px;
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.whitepapersFormBlock {
		.wrapper {
			padding: 90px;
			&.formWrapper {
				max-width: 842px;
			}
		}
	}
}
