.pagination {
    margin: 80px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .page-numbers {
        min-height: 40px;
        min-width: 40px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
		margin-left: 5px;
		margin-right: 5px;
		user-select: none;
		cursor: pointer;

		// standard page
		border: 1px solid #979797;
		border-radius: 4px;
		color: #5E5E5E;
		font-family: "Open Sans";
		font-size: 16px;
		letter-spacing: 0;
		line-height: 26px;

		&.current {
			background-color: #BAE9E4;
			border-color: #BAE9E4;
			cursor: auto;
		}

		&.next, &.prev {
			background-color: #24a0c6;
			border-color: #24a0c6;
			color: white;
			&:hover, &:focus {
				filter: brightness(1.2);
			}
		}

		&.disabled {
			cursor: not-allowed;
		}
    }
}
