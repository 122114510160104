.affiliation {
	width: 100%;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		h2 {
			margin-bottom: 3rem;
		}
		.logos__grid {
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: 1fr 1fr;
			.grid__block {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.affiliation {
		.wrapper {
			padding: 90px 60px;
			.logos__grid {
				grid-gap: 2rem;
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.affiliation {
		.wrapper {
			padding: 90px;
			.logos__grid {
				grid-template-columns: repeat(6, 1fr);
			}
		}
	}
}
