.accordionBlock {
	.wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 30px;
		.section {
			margin-bottom: 2rem;
			&:last-child {
				margin-bottom: 0;
			}
			.item {
				padding: 1rem 0 1.5rem;
				width: 100%;
				border-bottom: 1px solid #aebac7;
				display: flex;
				flex-wrap: wrap;
				cursor: pointer;
				&:first-of-type {
					border-top: 1px solid #aebac7;
				}
				.header {
					width: 100%;
					display: flex;
					align-items: center;
					position: relative;
					pointer-events: none;
					padding-right: 35px;
					h3 {
						margin-bottom: 0;
						font-family: helvetica;
						color: #292c2f;
					}
					&::after {
						position: absolute;
						top: calc(50% - 5px);
						right: 1rem;
						content: '';
						display: block;
						z-index: 5;
						height: 10px;
						width: 10px;
						border-bottom: 2px solid #000;
						border-right: 2px solid #000;
						transform: rotate(225deg);
						background-color: transparent;
						transition: all 0.3s ease-in-out;
					}
				}
				.expand {
					overflow: hidden;
					transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
					height: auto;
					max-height: 9999px;
					pointer-events: fill;
					.expand__block {
						padding-top: 1rem;
					}
				}
			}
		}
		.collapsible.collapsed {
			.expand {
				max-height: 0;
				transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
			}
			.header::after {
				transform: rotate(315deg);
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.accordionBlock {
		.wrapper {
			padding: 90px 60px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.accordionBlock {
		.wrapper {
			padding: 90px;
		}
	}
}
