.threeColumnContent {
	width: 100%;
	background-color: #f1f4f4;
	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 60px 30px;
		h3 {
			font: 700 36px/46px helvetica, Arial, sans-serif;
			letter-spacing: -0.47px;
			margin-bottom: 3rem;
			color: #292c2f;
		}
		.column__grid {
			display: grid;
			grid-gap: 1rem;
			grid-template-columns: 1fr;
			.grid__block {
				max-width: 360px;
				display: flex;
				flex-direction: column;
				img {
					width: 76px;
					height: 76px;
					margin-bottom: 1rem;
				}
				h4 {
					font: 700 18px/26px helvetica, Arial, sans-serif;
					letter-spacing: -0.4px;
					color: #292c2f;
				}
				p {
					color: #5e5e5e;
				}
			}
		}
	}
}

@media screen and (min-width: 800px) {
	.threeColumnContent {
		.wrapper {
			padding: 90px 60px;
			.column__grid {
				grid-gap: 2rem;
				grid-template-columns: repeat(3, 1fr);
				.grid__block {
					max-width: unset;
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.threeColumnContent {
		.wrapper {
			padding: 90px;
			.column__grid {
				grid-gap: 4rem;
			}
		}
	}
}
