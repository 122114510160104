nav {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 20;
	width: 100%;
	transition: all 0.3s ease-in-out;
	.navigation {
		background-color: transparent;
		width: 100%;
		.wrapper {
			width: 100%;
			max-width: 1440px;
			margin: 0 auto;
			padding: 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.logo__container img {
				filter: brightness(10);
				transition: all 0.3s ease-in-out;
			}
			.main__links,
			.right__links {
				display: none;
			}
		}
	}
	&.filled,
	&.permaFilled {
		background-color: #fff;
		box-shadow: (1px 1px 15px 0 #e4e4e463);
		.navigation .wrapper {
			.logo__container img {
				filter: brightness(1);
			}
			.main__links ul li a,
			.right__links ul li a {
				color: #000;
				&.btn {
					color: #fff;
				}
			}
		}
	}
}

.mobileNav__button {
	border: none;
}

.mobileNav__close {
	border: none;
	position: fixed;
	width: 100vw;
	height: 100vh;
	left: 100%;
	top: 0;
	background: rgba(0, 0, 0, 0.1);
	cursor: initial;
	transition: 75ms transform 200ms ease-in-out;
	outline: none;
}

.mobileNav__open .mobileNav__close {
	transform: translateX(-100%);
	transition: transform 250ms ease-in-out;
}

.mobileNav__links {
	position: fixed;
	padding: 50px 20px 20px;
	height: 100vh;
	width: 100%;
	max-width: 375px;
	min-width: 300px;
	left: 100%;
	top: 0;
	text-align: center;
	overflow-y: scroll;
	transition: transform 200ms ease-in-out;
	background: white;
	box-shadow: 0 20px 14px 0 rgba(0, 0, 0, 0.15);
}

.mobileNav__open .mobileNav__links {
	transform: translateX(-100%);
	transition: 75ms transform 250ms ease-in-out;
}

.mobileNav__links a {
	display: block;
	font: 600 1.125rem/40px 'Open Sans';
	letter-spacing: -0.22px;
	margin: 2rem 0;
	color: #000;
	transition: transform 100ms linear;
}
.mobileNav__links a:hover,
.mobileNav__links a:focus,
.has_submenu:hover .nav_link,
.has_submenu:focus .nav_link,
.active,
.subNav_link:hover,
.subNav_link:focus {
	color: #000 !important;
	box-shadow: none;
}

.mobileNav__links button {
	width: 100%;
}

@media screen and (min-width: 800px) {
	nav {
		.navigation {
			.wrapper {
				padding: 30px 60px;
				.mobile__nav {
					display: none;
				}
				.main__links {
					display: block;
					ul {
						display: flex;
						align-items: center;
						li a {
							color: #fff;
							font-size: 16px;
							line-height: 22px;
							padding: 10px;
							transition: all 0.3s ease-in-out;
							&:hover,
							&:focus,
							&:active {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	nav {
		.navigation {
			.wrapper {
				padding: 30px 90px;
				.main__links {
					ul li a {
						padding: 10px;
					}
				}
				.right__links {
					display: block;
					ul {
						display: flex;
						align-items: center;
						li a {
							color: #fff;
							font-size: 16px;
							line-height: 22px;
							padding: 10px;
							transition: all 0.3s ease-in-out;
							&:hover,
							&:focus,
							&:active {
								text-decoration: underline;
							}
							&.btn {
								min-width: 130px;
								&:hover,
								&:focus,
								&:active {
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1100px) {
	nav {
		.navigation {
			.wrapper {
				.main__links {
					ul li a {
						padding: 10px 20px;
					}
				}
				.right__links {
					ul {
						li a {
							padding: 10px 20px;
						}
					}
				}
			}
		}
	}
}
