header {
	width: 100%;
	min-height: 580px;
	display: flex;
	padding-top: 115px;
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	&.hero__spacing {
		padding-top: 180px;
	}
	&.hero__full {
		min-height: calc(100vh - 162px);
	}
	.wrapper {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		padding: 45px 30px;
		z-index: 10;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			content: '';
			display: block;
			background-color: #000;
			opacity: 0.3;
			z-index: -1;
		}
		.content {
			max-width: 640px;
			h1 {
				color: white;
				margin-bottom: 2rem;
			}
			h2 {
				font: 700 32px/40px helvetica, 'Open Sans', Arial;
				letter-spacing: -0.75px;
				color: white;
				margin-bottom: 2rem;
			}
			p {
				font: 400 16px/22px 'Open Sans', Arial, sans-serif;
				color: white;
				margin-bottom: 2rem;
			}
			.buttons__container {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				a {
					min-height: 50px;
					min-width: 160px;
					margin: 0 1rem 1rem 0;
					&:last-child {
						margin: 0 0 1rem;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 800px) {
	header {
		min-height: calc(100vh - 161px);
		.wrapper {
			padding: 45px 60px;
			.content {
				h2 {
					font: 700 50px/56px helvetica, Arial, sans-serif;
					letter-spacing: -1px;
				}
				p {
					font: 400 20px/28px 'Open Sans', Arial, sans-serif;
				}
				.buttons__container {
					a {
						margin: 0 1rem 0 0;
						&:last-child {
							margin: 0;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	header {
		.wrapper {
			padding: 0 90px 45px;
		}
	}
}
